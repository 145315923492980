class OasyUtils {
    static toggleBusy($el, isBusy) {
        $el.toggleClass('busy', isBusy);
    }

    static oasyAjax(url, params) {
        var logAjaxCalls = true;

        var jqxhr = $.ajax({
            type: "POST",
            url: url,
            cache: false,
            contentType: "application/json; charset=utf-8",
            data: JSON2.stringify(params),
            dataType: "json",
        });
        jqxhr.fail(function(xhr, ajaxOptions, thrownError) {
            alert(thrownError);

            console.log('ajax error:');
            console.log(thrownError);
        });

        if (logAjaxCalls) {
            jqxhr.always(function(data) {
                console.log('=======================');
                console.log('ajax call [' + url + ']');
                console.log('params:');
                console.log(params);
                console.log('response data:');
                console.log(data);
                console.log('=======================');
            });
        }

        return jqxhr;
    }

    static updateQueryStringParam(key, value, filters) {
        var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
            urlQueryString = document.location.search,
            newParam = key + '=' + value,
            params = '?' + newParam;

        // If the "search" string exists, then build params from it
        if (urlQueryString) {
            var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
            var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

            if (typeof value == 'undefined' || value == null || value == '') { // Remove param if value is empty
                params = urlQueryString.replace(removeRegex, "$1");
                params = params.replace(/[&;]$/, "");

            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                params = urlQueryString.replace(updateRegex, "$1" + newParam);

            } else { // Otherwise, add it to end of query string
                params = urlQueryString + '&' + newParam;
            }
        }

        // no parameter was set so we don't need the question mark
        params = params == '?' ? '' : params;

        var stateObj = {
            filters: filters,
            app: true
        };

        window.history.pushState(stateObj, "", baseUrl + params);
    }
}

class ProdottiListing {
    constructor() {
        this.$listing = $('[data-prodotti-listing]');
    }

    init() {
        if (!this.$listing.length) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners() {
        var _this = this;

        var filters = $('.filtro [data-group]:checked');

        // console.log('ProdottiListing.addEventListeners', filters.size());

        filters.size() ? $('.group-azzerafiltri').addClass('active') : $('.group-azzerafiltri').removeClass('active');

        $('.filtro [name="gamma"]', this.$listing).on('click', function(event) {
            // radio button like behavior (un solo checkbox attivo)
            $('.filtro [name="gamma"]').prop('checked', false);
            $(this).prop('checked', true);

            OasyUtils.toggleBusy(_this.$listing, true);

            var urlFiltro = $(this).data('url');

            window.location = urlFiltro;
        });

        $('.filtro [data-group]', this.$listing).on('click', function(event) {
            var urlFiltro = $(this).data('url');
            if (urlFiltro) {
                // window.location = urlFiltro;
                if ('history' in window) {
                    window.history.pushState(null, document.title, urlFiltro);
                    _this.updateListing(true);
                } else {
                    window.location = urlFiltro;
                }
            } else {
                _this.updateListing(true);
            }
        });

        window.onpopstate = function(event) {

            var results = new RegExp('[\?&]filters=([^&#]*)').exec(window.location.search);
            var urlFilters = (results !== null) ? results[1].split(",") : [];

            var selectedFilters = [];
            $('.filtro [data-group]:checked').each(function(i, el) {
                selectedFilters.push($(el).val());
            });

            var listingNeedsUpdate = (selectedFilters.length != urlFilters.length) || !selectedFilters.every(function(element, index) {
                return urlFilters[index] != "" && element === urlFilters[index];
            });

            $('.filtro [data-group]').prop('checked', false);
            for (var i in urlFilters) {
                $('.filtro [value="' + urlFilters[i] + '"]').prop('checked', true);
            }

            if (listingNeedsUpdate) {
                _this.updateListing(false);
            }
        };
    }

    updateListing(updateQueryString) {
        var _this = this;

        OasyUtils.toggleBusy(this.$listing, true);

        var filters = {};
        var filtersUrl = [];

        $('.filtro [data-group]:checked').each(function(i, el) {
            var value = $(el).val();
            var group = $(el).data('group');

            if (filters[group] === undefined) {
                filters[group] = [];
            }

            filters[group].push(value);
            filtersUrl.push(value);
        });

        // console.log('updateListing', filtersUrl.length);

        filtersUrl.length ? $('.group-azzerafiltri').addClass('active') : $('.group-azzerafiltri').removeClass('active');

        if (updateQueryString) {
            OasyUtils.updateQueryStringParam("filters", filtersUrl.join(","), filters);
        }

        var ajaxData = {
            filters: filters,
            categoryDocId: window.productsListingConfig.categoryDocId,
            itemsPerPage: window.productsListingConfig.itemsPerPage,
        };

        OasyUtils.oasyAjax('/WS/wsProducts.asmx/GetProductsList', ajaxData)
            .done(function(data) {
                if (data.d.Status) {
                    $('.listing-prodotti').html(data.d.HTML);
                }
            })
            .always(function() { OasyUtils.toggleBusy(_this.$listing, false) });
    }
}

class Blog {
    constructor() {
        this.$blog = $('[data-blog]');
    }

    init() {
        if (!this.$blog.length) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners() {
        $('#search-blog .submit', this.$blog).on('click', function() {
            $(this).parent('form').submit();
        });
    }
}

class Newsletter {
    constructor() {
        this.$newsletter = $('[data-newsletter]');
    }

    init() {
        if (!this.$newsletter.length) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners() {
        $('.proprietario-di-option', this.$newsletter).each(function(i, option) {
            $('input[type="checkbox"]', option).on('change', function() {
                $('.anno-input', option).slideToggle(150, function() {
                    $('input', this).val('');
                });
            });
        });

        $('.is-allevatore input[type="checkbox"]', this.$newsletter).on('change', function() {
            $('.lavoro-con', this.$newsletter).slideToggle();
        });
    }
}

class Common {
    constructor() {
        this.addEventListeners()
    }

    addEventListeners() {
        $('body').on('click', '[data-scrollto]', function(event) {
            var scrollTime = 500;
            var scrollOffset = 100;
            var scrollTarget = $(this).attr('data-scrollto');

            $('html, body').animate({
                scrollTop: $('[data-scroll="' + scrollTarget + '"]').offset().top - scrollOffset
            }, scrollTime);
        });

        $('[data-accordion-toggle]').on('click', function(event) {
            var toggleSpeed = 150;
            $(this).toggleClass('is-open');
            $(this).hasClass('is-open') ? $(this).next().slideDown(toggleSpeed) : $(this).next().slideUp(toggleSpeed, function() {
                $('[data-accordion-toggle]', this).siblings().hide().removeClass('is-open');
            });
        });
    }
}

class Menu {
    constructor() {
        this.$header = $('.fixed-header');
        this.$langMenu = $('.menu-lang', this.$header);
        this.$searchForm = $('.search-form', this.$header);
    }

    init() {
        if (!this.$header.length) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners() {
        var _this = this;

        $('.search a', this.$header).on('click', function(event) {
            event.preventDefault();
            _this.slideToggle(_this.$searchForm);
        });

        $('.menu-lang-toggle', this.$header).on('click', function() {
            _this.slideToggle(_this.$langMenu);
        });

        $('body').on('click', function(event) {
            if (_this.$langMenu.hasClass('is-open')) {
                _this.slideToggle(_this.$langMenu);
            }
            if (_this.$searchForm.hasClass('is-open') && $(event.target) && !$('.search').has(event.target).length) {
                _this.slideToggle(_this.$searchForm);
            }
        });
    }

    slideToggle($el) {
        var animSpeed = 100;

        if ($el.hasClass('is-open')) {
            $el.slideUp(animSpeed, function() { $(this).removeClass('is-open') });
        }
        else {
            $el.slideDown(animSpeed, function() { $(this).addClass('is-open') });
        }
    }
}

(function($) {
    var common = new Common();
    var prodottiListing = new ProdottiListing();
    var newsletter = new Newsletter();
    var blog = new Blog();
    var menu = new Menu();

    $(document).ready(function() {
        prodottiListing.init();
        blog.init();
        newsletter.init();
        menu.init();
    });
}(jQuery));
